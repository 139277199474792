<script setup>
import { PhxIcon } from '@phx-styleguides/pia';
import { ref, onMounted, computed } from 'vue';
import * as Cookie from 'js-cookie';

const props = defineProps({
  promotions: {
    type: Object,
    required: true,
  },

  options: {
    type: Object,
    default: () => ({}),
  },

  isCollapsable: {
    type: Boolean,
    default: false,
  },

  inlineStyles: {
    type: String,
    default: '',
  },
});

const banner = ref(null);
const collapse = ref(null);
const isBannerVisible = ref(false);

const bannerPromotions = computed(() => {
  const cookie = Cookie.get('banner-status');
  const viewedPromos = !cookie ? [] : cookie.split(',');

  const promos = Object.values(props.promotions);
  return promos.filter((promo) => !viewedPromos.length || !viewedPromos.includes(promo.hash));
});

onMounted(() => {
  isBannerVisible.value = bannerPromotions.value.length !== 0;
  if (!isBannerVisible.value) {
    return;
  }

  if (banner.value) {
    new Toolkit.PromoBanner(banner.value, props.options);
  }

  if (props.isCollapsable && collapse.value) {
    new Toolkit.Collapse(collapse.value);

    collapse.value.addEventListener('click', () => {
      const viewedPromotions = Object.values(props.promotions)
        .map((promo) => promo.hash)
        .toString();

      Cookie.set('banner-status', viewedPromotions, { expires: 30, secure: true });
    });
  }
});
</script>

<template>
  <div
    v-show="isBannerVisible"
    id="promo-banner-wrapper"
    ref="collapse"
    class="promo-banner collapse is-expanded md:px-0"
    :style="inlineStyles"
  >
    <div class="container">
      <ul ref="banner" class="promo-banner-slideshow rich-text" role="presentation">
        <li
          v-for="promotion in bannerPromotions"
          :key="promotion.id"
          class="promo-banner__item"
          role="presentation"
          v-html="promotion.text"
        />
      </ul>
    </div>

    <div v-if="isCollapsable" class="promo-banner__close">
      <button
        class="btn btn--bare btn--fade btn--xs btn--icon-only"
        type="button"
        aria-expanded="true"
        data-toggle="collapse"
        data-target="#promo-banner-wrapper"
      >
        <phx-icon icon="cross" class="icon--xs" />
      </button>
    </div>
  </div>
</template>
